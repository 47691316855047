<template>
  <div class="smartHr">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          高校智慧人事
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">应用框架</h4>
        <span class="line"></span>
        <img src="@/assets/solute/SmartHr/gnt.png" alt="" style="width:1000px;margin-top:40px">
      </div>

      <div class="ywtd">
        <h4 class="descH4">业务痛点</h4>
        <div class="yetdTitleDes">
          当下正是高校数字化转型的关键时期，《教育部2022年工作要点》指出要加快推进教育数字转型和智能升级。教师是教育发展的根本，深化教师管理改革，提高教师的获得感和幸福感，就是要提高为教师服务的能力。目前高等院校人事业务管理模式效能遇到了挑战：
        </div>
        <span class="line"></span>
        <div class="yetdContent">
          <div v-for="(item,index) in yetdData" :key="index" class="yetdItem">
            <div class="valueItemMask"></div>
            <div class="yetdTitle">{{item.title}}</div>
            <div class="yetdLine"></div>
            <div class="yetdCon">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="solute">
        <h4 class="descH4">解决方案</h4>
        <div class="yetdTitleDes">
          高校人力资源在自身业务发展需求和技术力量的推动下，已经到了由量变到质变的新阶段。新时代高校人力资源转型的最大特点就是实现人力资源价值最大化和人力资源业务数字化。
        </div>
        <span class="line"></span>
        <div class="yetdContent">
          <div v-for="(item,index) in soliteData" :key="index" class="yetdItem">
            <div class="valueItemMask"></div>
            <div class="yetdTitle">{{item.title}}</div>
            <div class="yetdLine"></div>
            <div class="yetdCon">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="applicationCase">
        <h4 class="descH4">应用案例</h4>
        <span class="line"></span>
        <div class="appCaseCon">
          <el-carousel indicator-position="outside" class="caseContent" arrow="never">
            <el-carousel-item v-for="(item,index) in caseData" :key="index" class="caseItem">
              <div class="caseLeft">
                <img :src="item.src" alt="" style="width:100%;height:100%">
              </div>
              <div class="caseRight">
                <div class="caseTitle">{{item.title}}</div>
                <div class="caseRIghtCon">{{item.content}}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <Foot></Foot>
    </div>
    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'SmartHr',
  data () {
    return {
      yetdData: [
        { title: '服务有待提升', content: '办理人事业务，教师需要多次往返人事处及相关职能部门，教职工跑断腿。' },
        { title: '工作被动保守', content: '“以事为本”的“行政化管理”，导致按事配人，忽视了用人单位对人才本身的关注。' },
        { title: '各自为营', content: '很多业务部门根据自身的业务需求，建有人事信息台账，台账维护需要逐条核对，信息存在重复、不统一和不准确的情况。' },
        { title: '无法实时共享', content: '各部门的人事信息台账与其他部门信息无法共享，导致信息更新不及时。' },
        { title: '数据统计和上报困难', content: '因信息分散、统计不全、时效性差，教育主管部门需要上报各类数据报表时，只能耗费大量的人工和时间收集、核查、统计数据，工作效率差。' },
        { title: '人手捉襟见肘', content: '人事业务内容广，牵涉面大，流程复杂，涵盖了教职工全生命周期的管理与服务，如招聘、报到入职、转正定级、校内调动、合同、考勤、请假、薪酬、评聘、考核、培养、出国、退休、离职、去世等，需要人手予以支撑，但受制于人力有限，无法得到有效满足。' }
      ],
      soliteData: [
        { title: '一数一源，整合人事数据，消除信息孤岛', content: '建立数据责任制，规范信息标准和统计口径，建设全域教职工信息库，通过科学的数据采集和数据管理机制，多渠道采集、整合、维护和存储与教职工相关的人事、教学、科研等360度全方位数据信息，并按不同角色不同权限开放共享给各级用户使用。' },
        { title: '优化招聘流程，助力人才引进', content: '实现招聘及人才引进业务全流程化、集约化、一站式办理，包括招聘计划、职位发布、简历投递、简历筛选、组织笔试、组织面试、体检、拟录用等环节，应聘人员的信息同步审核，拟录用简历流转至报到入职模块，减少重复填写。' },
        { title: '业务创新，提升人力资源管理专业能力', content: '教职工从入校到离校期间核心业务，从报到入职、转正定级、合同签订、校内调动、职称评审、考核测评、到退休办理，业务环环相扣，数据涵盖教职工完整生命周期历程。通过建立能力评价模型等专业工具，在人才开发等方面进行业务创新，做到人尽其才，提升人力资源管理专业能力，不断发挥出人力资源管理在学校发展中的价值和作用。' },
        { title: '构建数字看板，支撑科学决策', content: '构建人力资源数字看板，为中高管理层提供“一站式”决策支持服务。通过丰富多样的统计图表样式，呈现多维度的专任教师分析、高层次人才分析、收入结构分析等，实时全面的了解全校的人事情况、发展趋势，推动管理向精细化、深层次迈进。' },
        { title: '便捷自助服务，提升服务响应能力', content: '围绕教职工全生命周期，建设面向教职工的自助服务应用。以人为本，面向不同角色、不同业务、不同场景构建人性化、泛在化的服务和应用，提升多端异屏体验的友好性、一致性；发挥信息化在个人职业发展和工作效能提升方面的价值。' },
        { title: '全面系统集成，实现共治共享', content: '支持人力资源管理系统与智慧校园的整合与兼容，能够与智慧校园平台、财务、教务、科研等系统进行集成与融合，实现互联互通、数据共享，为数字化校园提供数据支撑。' }
      ],
      caseData: [
        { src: require('@/assets/TypicalCase/typeCase/zsdx.png'), title: '中山大学', content: '2017年易普行开始参与中山大学人力资源管理系统的规划和建设，目前人力资源管理系统已完成了三期建设，基本实现了教职工进校、在校、离校的全生命周期业务管理，包括招聘、报到入职、合同、考勤、人员调配、离校、退休、岗位聘任、专家评审、投票、考核、出国、教师培训、薪酬、自助打印等业务，并基于平台开发了项目管理、盖章管理、通讯录等个性化的功能模块。系统功能亮点突出，在数据层面，实行数据责任制，数据管理人人有责；在业务层面，完全根据学校的需求定制开发业务模块，业务模式高度匹配；在服务层面，为教职工提供最大化的便捷自助服务，服务获取随时随地；从“数据、业务、服务”三个层面提升了学校整体人力资源管理与服务能力。' },
        { src: require('@/assets/solute/SmartHr/whdx.png'), title: '武汉大学', content: '武汉大学人力资源管理与服务平台的建设，是在“智慧校园”建设的整体规划下，采取分期建设、持续优化的策略，于2017年开始建设一期，已完成机构管理、教职工信息库、人事管理、薪酬福利、招聘管理、社保管理、年度考核、统计分析、综合服务等28个模块，并为32个角色提供服务，将学校日常的人事人才管理业务流程化、规范化、秩序化，建立了人事人才信息化标准，沉淀了大量教职工数据，梳理和优化了人事人才管理和服务流程，提升了人事人才管理的效率和服务水平。武汉大学人力资源管理与服务平台，自投入使用以来，逐步实现教职工入校、在校、离校全生命周期的业务管理与服务，应用效果显著。' },
        { src: require('@/assets/TypicalCase/typeCase/jldx.png'), title: '吉林大学', content: '中山大学人力资源信息化通过整体规划、分期建设、分步实施、持续优化的策略，在不断与智慧校园融合的过程中，将人力资源管理系统建设成为全校型的管理与服务平台，从“数据、业务、管理、服务、决策”五个层面全面提升人力资源管理与服务能力，形成了以“数据驱动应用建设，应用助推数据建设”的工作特色。在人力资源信息化建设中，实现对学校分散的17000多名教职工数据进行梳理、建模、整合、评价、控制，制定数据标准和工作规范，提升数据共享效率，完善数据管理体系对学校数据进行监督和控制，建立严格的数据质量管理流程体系，加大跨部门数据质量管控力度与支撑各相关业务高效运行，支持学校管理者方便、安全、快速、可靠地利用数据进行决策。' }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moSlution/moSchool')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.smartHr{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/solute/SmartHr/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}

.systemArchitecture{
  width: 100%;
  height: 750px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ywtd{
  width: 100%;
  height: 1000px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yetdTitleDes{
  width: 900px;
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.yetdContent{
  width: 1200px;
  margin-top:40px;
  display: flex;
  flex-wrap: wrap;
}
.yetdItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  // min-height: 180px;
  // max-height: 220px;
  margin-right: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(220,223,230,0.5);
  padding: 31px 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.yetdItem:nth-child(2n){
  margin-right: 0px;
}
.yetdLine{
  width: 30px;
  height: 4px;
  background: #D70C0C;
  margin: 14px 0 16px;
}
.yetdTitle{
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 36px;
}
.yetdCon{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
}
.yetdItem:hover{
   box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border: 0px;
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .yetdTitle,.yetdCon{
    color: #fff;
  }
  .yetdLine{
    background-color: #fff;
  }
}

.solute{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.applicationCase{
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appCaseCon{
  height: 490px;
}
.caseContent{
  width: 1200px;
  min-height: 400px;
  padding-top: 60px;
}
.caseContent{
  &::v-deep{
    .el-carousel__container{
      height: 330px;
    }
  }
}

.caseItem{
  width: 100%;
  height: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(211,218,228,0.5);
  cursor: pointer;
}
.caseItem:hover{
  box-shadow: 0px 4px 20px -4px rgba(0,0,0,0.15);
}
.caseLeft{
  width: 460px;
  height: 100%;
}
.caseRight{
  width: 740px;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
}
.appCaseCon{
  &::v-deep {
      .el-carousel__button {
      width: 20px;
      height: 6px;
      background: #d70c0c !important;
      border-radius: 3px;
    }
    .el-carousel__indicators--outside{
      margin-top: 30px;
    }
  }
}
.caseTitle{
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 28px;
}
.caseRIghtCon{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 26px;
  padding-top: 12px;
}
</style>
